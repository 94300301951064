"use client";

import styled from "styled-components";
import { Container } from "@/shared/ui/container";

export const FeatureText = styled.div`
  color: var(--secondary-text);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.2px;
`;

export const FeatureName = styled.div`
  color: var(--primary-text);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
`;

export const FeatureWrapper = styled.div`
  .btn {
    margin-top: 35px;
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 900px) {
    .btn {
      margin: 35px auto 0 auto;
    }
  }

  @media (max-width: 375px) {
    .btn {
      width: 100%;
      max-width: unset;
    }
  }
`;

export const FeatureList = styled.div`
  height: 80%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 200px;

  @media (max-width: 1150px) {
    gap: 150px;
  }

  @media (max-width: 1030px) {
    gap: 100px;
  }
`;

export const ImagesWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 450px;
  margin: 0 0 0 auto;

  img {
    max-width: 450px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 2;

    &#phones-mobile {
      display: none;
    }

    &:nth-child(2) {
      position: absolute;
      transform: translateX(-20%);
      z-index: 1;
    }
  }

  @media (max-width: 1440px) {
    max-width: 380px;
  }

  @media (max-width: 900px) {
    img {
      max-width: unset;
      max-height: 500px;
    }
  }

  @media (max-width: 768px) {
    img:not(#phones-mobile) {
      display: none;
    }

    img#phones-mobile {
      display: block;
    }
  }
`;

export const Wrapper = styled(Container)`
  display: grid;
  grid-template-columns: 310px auto 310px;
  gap: 50px;

  @media (max-width: 1030px) {
    grid-template-columns: 4fr 4fr 4fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;

    > * {
      margin: 0 auto;
      align-items: center;
      text-align: center;
      gap: 50px;
    }

    ${ImagesWrapper} {
      grid-row: 1;
    }
    > ${FeatureList}:first-child {
      grid-row: 2;
    }
  }
`;

'use client';

import React from 'react';
import Image from 'next/image';
import phoneFront from '@/public/staticfiles/download/iphone-min.png';
import phoneBack from '@/public/staticfiles/download/iphone-skewed-min.webp';
import phonesMobile from '@/public/staticfiles/download/download-features-phones-mobile-min.png';
import * as SC from './styled';

function DownloadFeaturesBlock() {
  return (
    <SC.Wrapper>
      <SC.FeatureList>
        <SC.FeatureWrapper>
          <SC.FeatureName>Multi-Account Management</SC.FeatureName>
          <SC.FeatureText>Effortlessly switch between personal and professional accounts</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Personal AI Assistant</SC.FeatureName>
          <SC.FeatureText>Smart, responsive, and always ready to assist</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Always in Sync</SC.FeatureName>
          <SC.FeatureText>Stay updated with the latest Telegram features and innovations</SC.FeatureText>
        </SC.FeatureWrapper>
      </SC.FeatureList>
      <SC.ImagesWrapper>
        <Image height={900} width={450} src={phoneFront} alt="" />
        <Image height={900} width={450} src={phoneBack} alt="" />
        <Image id="phones-mobile" height={900} width={450} src={phonesMobile} alt="" />
      </SC.ImagesWrapper>
      <SC.FeatureList>
        <SC.FeatureWrapper>
          <SC.FeatureName>Built-In Translator</SC.FeatureName>
          <SC.FeatureText>Break language barriers with instant translations</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Top-Notch Privacy</SC.FeatureName>
          <SC.FeatureText>Your conversations are safe with state-of-the-art privacy features</SC.FeatureText>
        </SC.FeatureWrapper>{' '}
        <SC.FeatureWrapper>
          <SC.FeatureName>Nicegram – Where messaging meets innovation.</SC.FeatureName>
          <SC.FeatureText>More than 20+ features are available</SC.FeatureText>
        </SC.FeatureWrapper>
      </SC.FeatureList>
    </SC.Wrapper>
  );
}

export { DownloadFeaturesBlock };

"use client";

import styled from "styled-components";
import arrow from "@/public/staticfiles/download/arrow-left.svg";
import { Container } from "@/shared/ui/container";
import { Card } from "@/shared/ui/card";

export const Wrapper = styled(Container)`
  ${Card} {
    padding: 0;
    display: flex;

    > div {
      padding: 0 16px;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
      position: relative;

      &:after {
        display: block;
        content: url(${arrow.src});
        position: absolute;
        right: 0;
        bottom: 50%;
        transform: translate(-50%, 100%) rotate(-55deg);
      }

      h4 {
        margin: 0;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.4px;
      }

      .btn {
        max-width: 300px;

        &:after {
          display: none !important;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    ${Card} {
      > div {
        &:after {
          display: none;
        }
      }
    }
  }
  @media (max-width: 900px) {
    ${Card} {
      align-items: center;
      padding-bottom: 24px;
      flex-direction: column;

      div {
        margin: 0 auto;
        align-items: center;
        text-align: center;

        .btn {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    ${Card} {
      img {
        width: 100%;
        height: auto;
      }
      div {
        padding: 0 16px;
        h4 {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
`;
